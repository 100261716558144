import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppLayoutComponent} from './layout/app.layout.component';
import {MyReplyProductRoutingModule} from "./pages/demand/my_reply_product/my_reply_product-routing.module";
import {InvoiceListModule} from "./pages/demand/invoice_list/invoice_list.module";

const routes: Routes = [
  {
    path: '', component: AppLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {animationName: '/'}
      },
      {
        path: 'product/list',
        loadChildren: () => import('./pages/product/product_list/product_list.module').then(m => m.ProductListModule),
        data: {animationName: 'product/list'}
      },
      {
        path: 'demand/add',
        loadChildren: () => import('./pages/demand/add_demand/add_demand.module').then(m => m.AddDemandModule),
        data: {animationName: 'demand/add'}
      },
      {
        path: 'demand/list',
        loadChildren: () => import('./pages/demand/demand_list/demand_list.module').then(m => m.DemandListModule),
        data: {animationName: 'demand/list'}
      },
      {
        path: 'demand/operate_order',
        loadChildren: () => import('./pages/demand/demand_order_list/demand_order_list.module').then(m => m.DemandOrderListModule),
        data: {animationName: 'demand/operate_order'}
      },
      {
        path: 'demand/book_product',
        loadChildren: () => import('./pages/demand/my_reply_product/my_reply_product.module').then(m => m.MyReplyProductModule),
        data: {animationName: 'demand/book_product'}
      },
      {
        path: 'demand/invoice',
        loadChildren: () => import('./pages/demand/invoice_list/invoice_list.module').then(m => m.InvoiceListModule),
        data: {animationName: 'demand/invoice'}
      }
    ]
  },
  {path: 'login', loadChildren: () => import('./pages/user/login/login.module').then(m => m.LoginModule)},
  {path: 'register', loadChildren: () => import('./pages/user/register/register.module').then(m => m.RegisterModule)},
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
