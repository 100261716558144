const baseUrl = 'api';

export const Apis = {
  user: {
    post_login: baseUrl + '/login',
    post_logout: baseUrl + '/logout',
    post_register: baseUrl + '/register',
    get_user_info: baseUrl + '/user/who_am_i',
    get_findSupplyByDemand: baseUrl + '/user/find_supply',
    get_findDemandBySupply: baseUrl + '/user/find_demand',
    get_findDemandExcelExportHeaderSetting: baseUrl + '/user/demand_excel_export_header_setting',
    get_findInvoiceExcelExportHeaderSetting: baseUrl + '/user/invoice_excel_export_header_setting',
    post_addExcelHeaderSetting: baseUrl + '/user/excel_export_header_setting',
    put_updateExcelHeaderSetting: baseUrl + '/user/excel_export_header_setting',
    put_deleteExcelHeaderSetting: baseUrl + '/user/excel_export_header_setting/delete',
    get_findExcelImportHeaderSetting: baseUrl + '/user/excel_import_header_setting',
    get_findInvoiceHeaderSetting: baseUrl + '/user/invoice_header_setting',
    get_findInvoiceHeaderSettingByDemand: baseUrl + '/user/invoice_header_setting/find_by_demand/:demandUserId',
    post_addInvoiceHeaderSetting: baseUrl + '/user/invoice_header_setting',
    put_updateInvoiceHeaderSetting: baseUrl + '/user/invoice_header_setting',
    put_updateInvoiceHeaderSettingDemandUserId: baseUrl + '/user/invoice_header_setting/demand_user',
    put_deleteInvoiceHeaderSetting: baseUrl + '/user/invoice_header_setting/delete',
    get_findInvoiceExcelImportHeaderSetting: baseUrl + '/user/invoice_excel_import_header_setting',
    post_updateUserAvatar: baseUrl + '/user/update_avatar',
    put_updateUser: baseUrl + '/user/update_user',
    post_findUserByNameOrPhone: baseUrl + '/user/find_user_by_name_or_phone',
    post_addSupplyDemandRelationships: baseUrl + '/user/supply_demand_relationships',
    put_updateSupplyDemandRelationshipCostPercent: baseUrl + '/user/update_supply_demand_relationship_cost_percent',
    get_findMessageByTargetUser: baseUrl + '/message/my_all',
    put_updateMessageRead: baseUrl + '/message/read',
    put_batchUpdateMessageRead: baseUrl + '/message/batch_read',
    put_readAllMessage: baseUrl + '/message/read_all',
    get_findUnreadMessageByTargetUser: baseUrl + '/message/my_unread',
    get_findDemandBySupply2: baseUrl + '/user/find_demand_2',
    get_findSupplyByDemand2: baseUrl + '/user/find_supply_2'
  },
  product: {
    post_readBarcode: baseUrl + '/product/image_barcode',
    post_saveProduct: baseUrl + '/product/add_with_image',
    get_findProductByBarcode: baseUrl + '/product/find_by_barcode/:barcode',
    get_findProductByProductCode: baseUrl + '/product/find_by_product_code/:productCode',
    get_findProductByProductName: baseUrl + '/product/find_by_product_name/:productName',
    get_findImageByBarcode: baseUrl + '/image/find_by_barcode/:barcode',
    get_findDemandProductByBarcode: baseUrl + '/product/find_demand_product_by_barcode/:barcode',
    get_findDemandProductByProductCode: baseUrl + '/product/find_demand_product_by_product_code/:productCode',
    get_findDemandProductByProductName: baseUrl + '/product/find_demand_product_by_product_name/:productName',
    get_findProductBySystem: baseUrl + '/product/find_by_system',
    post_saveProduct2: baseUrl + '/product/batch_add',
    post_batchAddProduct2: baseUrl + '/product/batch_add2',
    put_updateProductImage: baseUrl + '/product/update_image',
    put_updateSystemProduct: baseUrl + '/product',
    put_deleteSystemProduct: baseUrl + '/product/delete',
    get_getCategory: baseUrl + '/product/category',
    post_findSupplyProduct: baseUrl + '/product/supply',
    post_findSupplyProductCount: baseUrl + '/product/supply/count',
    post_findSystemProduct: baseUrl + '/product/system',
    post_findSystemProductCount: baseUrl + '/product/system/count',
    post_findMyProduct: baseUrl + '/product/my',
    post_findMyProductCount: baseUrl + '/product/my/count',
    post_uploadImage: baseUrl + '/product/upload_image'
  },
  demandOrder: {
    post_addDemandOrder: baseUrl + '/demand_order',
    post_addDemandOrder2: baseUrl + '/demand_order2',
    put_updateDemandOrder: baseUrl + '/demand_order',
    put_updateDemandOrder2: baseUrl + '/demand_order2',
    get_findInitDemandOrderListByUser: baseUrl + '/demand_order/my_init',
    get_findReplyDemandOrderListByUser: baseUrl + '/demand_order/my_reply',
    get_findMyPublishDemandOrderListByUser: baseUrl + '/demand_order/my_publish',
    get_findMyAllPublishDemandOrderList: baseUrl + '/demand_order/my_all_publish',
    put_updateDemandOrderNumber: baseUrl + '/demand_order/update_number',
    put_deleteDemandOrder: baseUrl + '/demand_order/delete',
    put_updateDemandOrderSupply: baseUrl + '/demand_order/update_supply',
    post_exportExcel: baseUrl + '/demand_order/excel',
    post_exportImage: baseUrl + '/demand_order/image',
    post_exportPdf: baseUrl + '/demand_order/pdf',
    get_findInitDemandOrderListByPublish: baseUrl + '/demand_order/publish',
    get_findInitDemandOrderListByReply: baseUrl + '/demand_order/reply',
    get_findInitDemandOrderListByConfirm: baseUrl + '/demand_order/confirm',
    get_findInitDemandOrderListBySupplyUser: baseUrl + '/demand_order/supply_user',
    post_replyDemandOrder: baseUrl + '/demand_order/reply',
    post_replyDemandUserOrders: baseUrl + '/demand_order/reply_user',
    post_confirmReplyDemandOrder: baseUrl + '/demand_order/confirm',
    post_closeDemandOrder: baseUrl + '/demand_order/close',
    post_batchConfirmReplyDemandOrders: baseUrl + '/demand_order/batch_confirm',
    post_batchCloseDemandOrders: baseUrl + '/demand_order/batch_close',
    post_batchCopyDemandOrders: baseUrl + '/demand_order/batch_copy',
    post_batchCopyAndCloseDemandOrders: baseUrl + '/demand_order/batch_copy_and_close',
    get_findConfirmDemandOrderListByDemandAndSupply: baseUrl + '/demand_order/confirm/:userId',
    get_getReplyDemandOrderByBarcode: baseUrl + '/demand_order/reply/get_by_barcode/:barcode',
    get_findInitDemandOrderListByOrderNumber: baseUrl + '/demand_order/get_by_order_number/:orderNumber',
    get_findUserInitDemandOrderListByPublish: baseUrl + '/demand_order/get_by_user/:userId',
    post_replyConfirmDemandOrders: baseUrl + '/demand_order/reply_confirm',
    get_findInitDemandOrderByReplyOrderNumber: baseUrl + '/demand_order/get_by_reply_order_number/:orderNumber',
    get_findDemandOrderListByUserAndSupply: baseUrl + '/demand_order/get_by_user_and_supply/:userId',
    get_findMyReplyDemandOrderListByUser2: baseUrl + '/demand_order/my_reply_2',
  },
  demandOrderGroup: {
    get_findDemandOrderGroupByUserId: baseUrl + '/demand_order_group/my',
    post_closeDemandOrder: baseUrl + '/demand_order_group/close',
    put_deleteDemandOrder: baseUrl + '/demand_order_group/delete',
    get_findDemandOrderGroupBySupplyUserId: baseUrl + '/demand_order_group/supply',
  },
  replyDemandOrderGroup: {
    get_findReplyDemandOrderGroupByUserId: baseUrl + '/reply_demand_order_group/my'
  },
  invoice: {
    post_addInvoice: baseUrl + '/invoice',
    post_addInvoices: baseUrl + '/invoices',
    post_addInvoices2: baseUrl + '/invoices2',
    put_updateInvoice: baseUrl + '/invoice',
    post_sendToDemandUser: baseUrl + '/invoice/send_to_demand_user',
    post_sendToDemandUsers: baseUrl + '/invoice/send_to_demand_users',
    get_getMyInvoiceList: baseUrl + '/invoice/my_list',
    get_getMyAllInvoiceList: baseUrl + '/invoice/my_all_list',
    get_getInvoiceById: baseUrl + '/invoice/:id',
    get_getInvoiceByDemandUser: baseUrl + '/invoice/demand_user_list',
    post_exportExcel: baseUrl + '/invoice/excel',
    post_exportImage: baseUrl + '/invoice/image',
    post_exportPdf: baseUrl + '/invoice/pdf',
    get_findSupplyInvoiceProductByDemandOrderBarcode: baseUrl + '/invoice/find_supply_product_by_demand_barcode',
    get_findDemandInvoiceProductByDemandOrderBarcode: baseUrl + '/invoice/find_demand_product_by_demand_barcode',
    get_getInvoiceByInvoiceNumber: baseUrl + '/invoice/get_by_invoice_number/:invoiceNumber',
    post_saveInvoiceAndSend: baseUrl + '/invoice/save_invoice_and_send',
    put_updateInvoiceInfo : baseUrl + '/update_invoice_info',
    put_updateInvoiceInfoByDemand : baseUrl + '/update_invoice_info_by_demand',
    post_batchCopyToDemand : baseUrl + '/invoice/batch_copy_to_demand',
    put_updateInvoiceToModify : baseUrl + '/invoice/update_to_modify'
  },
  orderNotify: {
    post_addNotifyList: baseUrl + '/add_order_notify_list',
    get_getMyOrderNotify: baseUrl + '/my_order_notify',
    put_updateInvoiceToDone : baseUrl + '/invoice/update_to_done'
  }
}
